import axios from "axios"
import { defineStore } from "pinia"
import { computed, ref, watch } from "vue"

export const useConfigStore = defineStore(
    "config",
    () => {
        const projectURL = `${import.meta.env.VITE_BASE_URL}`
        const apiURL = `${import.meta.env.VITE_API_URL}`
        const imageURL = `${import.meta.env.VITE_IMAGE_URL}`

        const projectTitle = `${import.meta.env.VITE_PROJECTTITLE}`

        const isProduction = import.meta.env.VITE_VERSION !== "dev"

        const isChatOpen = ref<boolean>(false)
        const isSidebarOpen = ref<boolean>(false)
        const isLoading = ref<boolean>(false)

        // ------------- NETWORK ---------------------

        const online = ref<boolean>(true)
        const serverOnline = ref<boolean>(true)

        watch(serverOnline, () => {
            console.log("serverOnline", serverOnline)
        })

        // ------------- LANGUAGE ---------------------

        const locale = ref<string>("de")

        // ------------- DARK-MODE ---------------------

        const systemDarkMode = ref<boolean>(
            window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
        )
        const darkModeSettings = ref<"light" | "dark" | "system">("system")
        const darkModeMenu = ref<boolean>(false)

        const darkMode = computed<"light" | "dark">(() => {
            const temp =
                darkModeSettings.value === "system"
                    ? systemDarkMode.value
                        ? "dark"
                        : "light"
                    : darkModeSettings.value
            document.getElementsByTagName("html")[0].className = temp
            return temp
        })

        const isDarkMode = computed<boolean>(() => darkMode.value === "dark")

        if (window.matchMedia) {
            window
                .matchMedia("(prefers-color-scheme: dark)")
                .addEventListener("change", (event) => {
                    systemDarkMode.value = event.matches
                })
        }

        watch(darkModeSettings, async (newValue) => {
            document.getElementsByTagName("html")[0].className =
                newValue === "system" ? (systemDarkMode.value ? "dark" : "light") : newValue
        })

        document.getElementsByTagName("html")[0].className =
            darkModeSettings.value === "system"
                ? systemDarkMode.value
                    ? "dark"
                    : "light"
                : darkModeSettings.value

        // -----------

        axios.defaults.baseURL = apiURL
        axios.defaults.headers.post["Content-Type"] = "application/json"
        axios.defaults.headers.put["Content-Type"] = "application/json"
        axios.defaults.timeout = 20000

        return {
            darkMode,
            darkModeMenu,
            darkModeSettings,
            imageURL,
            isChatOpen,
            isDarkMode,
            isLoading,
            isProduction,
            locale,
            online,
            projectTitle,
            projectURL,
            serverOnline,
            sidebarOpen: isSidebarOpen
        }
    },
    {
        persist: {
            pick: ["locale", "darkModeSettings"]
        }
    }
)
