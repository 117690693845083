<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

import AsyncButton from "@/components/atoms/form/AsyncButton.vue"
import Checkbox from "@/components/atoms/form/checkbox.vue"
import InlineLoader from "@/components/atoms/loader/InlineLoader.vue"
import EmailSentMessage from "@/components/lottie/EmailSentMessage.vue"

import type { LoginFormData } from "@/interfaces/auth.interface"

import authService from "@/services/Auth.service"

import { useAuthStore } from "@/stores/authStore"

import AsyncForm from "@/composables/form/AsyncForm.vue"

const authStore = useAuthStore()
const router = useRouter()
const { t } = useI18n()

const loginData = ref<LoginFormData>({
    email: "",
    otp: null,
    password: ""
})

const step = ref<
    "email" | "resendEmail" | "emailSent" | "fido" | "password" | "twoFactor" | "forgot"
>("email")

const emailLogin = async () => {
    try {
        const result = await authStore.loginEmail(loginData.value.email)
        if (result?.resendEmail) {
            step.value = "resendEmail"
        } else if (result?.challenge) {
            loginData.value.challenge = result.challenge
            step.value = "fido"
            try {
                await authStore.fidoAuthenticate(loginData.value.email)
            } catch (e) {
                step.value = "password"
            }
        } else {
            step.value = "password"
        }
    } catch (error) {
        console.error("Error during email login:", error)
    }
}

const passwordLogin = async () => {
    try {
        const result = await authStore.loginPassword(
            loginData.value.email,
            loginData.value.password
        )
        if ("twoFactor" in result) {
            step.value = "twoFactor"
        } else {
            await router.push({ name: "feed" })
        }
    } catch (error) {
        console.error("Error during password login:", error)
    }
}

const twoFactorLogin = async () => {
    try {
        await authStore.loginTwoFactor(
            loginData.value.email,
            loginData.value.password,
            loginData.value.otp as string
        )
        await router.push({ name: "feed" })
    } catch (error) {
        console.error("Error during twofactor login:", error)
    }
}

const resendEmail = async () => {
    try {
        await authService.resendEmail(loginData.value.email)
        step.value = "emailSent"
    } catch (error) {
        console.error("Error during email resend:", error)
    }
}
</script>
<template>
    <div
        class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm animate__animated animate__fadeIn animate__delay-1s flex flex-col justify-center items-center">
        <transition mode="out-in" name="slide-fade" class="w-full">
            <div v-if="step === 'email'">
                <AsyncForm v-slot="{ loading, action }" :action="emailLogin">
                    <div class="flex flex-col gap-4">
                        <div>
                            <label for="email" class="block text-sm font-satoshiMedium leading-6">{{
                                t("labels.email")
                            }}</label>
                            <div class="mt-2">
                                <input
                                    id="email"
                                    v-model="loginData.email"
                                    name="email"
                                    type="email"
                                    autocomplete="email"
                                    required
                                    placeholder="max@mustermann.de"
                                    class="text-gray-800 px-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus-visible:outline-none focus:ring-primary sm:text-sm sm:leading-6"
                                    @keydown.enter="action" />
                            </div>
                        </div>

                        <div class="flex items-center gap-2">
                            <Checkbox
                                :checked="!authStore.session"
                                @click="authStore.session = !authStore.session" />
                            <p class="block text-sm font-satoshiMedium leading-6">
                                {{ t("labels.stayLoggedIn") }}
                            </p>
                        </div>

                        <div>
                            <AsyncButton
                                class="btn btn-primary w-full"
                                :loading="loading"
                                @click="action">
                                {{ $t("basic.next") }}
                            </AsyncButton>
                        </div>
                    </div>
                </AsyncForm>
            </div>
            <div v-else-if="step === 'resendEmail'">
                {{ $t("messages.resendEmailPrompt") }}
                <div class="flex flex-row gap-4">
                    <button class="btn btn-primary" @click="resendEmail">
                        {{ $t("basic.yes") }}
                    </button>
                    <button class="btn btn-secondary" @click="step = 'email'">
                        {{ $t("basic.back") }}
                    </button>
                </div>
            </div>
            <EmailSentMessage v-else-if="step === 'emailSent'" :email="loginData.email" />
            <div v-else-if="step === 'fido'" class="flex flex-col justify-center items-center">
                <InlineLoader />
                {{ t("messages.waitingForAuth") }}
            </div>
            <div v-else-if="step === 'password'">
                <div class="space-y-6">
                    <AsyncForm v-slot="{ action, loading }" :action="passwordLogin">
                        <div class="flex flex-col gap-4">
                            <label
                                for="password"
                                class="block text-sm font-satoshiMedium leading-6 text-gray-900"
                                >{{ $t("labels.password") }}</label
                            >
                            <div class="mt-2">
                                <input
                                    id="password"
                                    v-model="loginData.password"
                                    autofocus
                                    name="password"
                                    type="password"
                                    required
                                    class="text-gray-800 px-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus-visible:outline-none focus:ring-primary sm:text-sm sm:leading-6"
                                    @keydown.enter="action" />
                            </div>
                            <div
                                class="cursor-pointer text-blue-600"
                                @click="
                                    async () => {
                                        try {
                                            await authService.forgotPassword(loginData.email)
                                            step = 'forgot'
                                        } catch {
                                            //nothing
                                        }
                                    }
                                ">
                                {{ t("labels.forgotPassword") }}
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <AsyncButton
                                :loading="loading"
                                type="submit"
                                class="duration-300 hover:scale-105 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-satoshiSemibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                                @click="action">
                                {{ t("auth.login.title") }}
                            </AsyncButton>
                            <button
                                v-if="authStore.fidoAvailable && loginData.challenge"
                                type="button"
                                class="duration-300 hover:scale-105 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-satoshiSemibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                                @click="authStore.fidoAuthenticate(loginData.email)">
                                {{ t("auth.login.fido") }}
                            </button>
                            <button
                                type="button"
                                class="duration-300 hover:scale-105 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-satoshiSemibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                                @click="
                                    () => {
                                        step = 'email'
                                        loginData.password = ''
                                    }
                                ">
                                {{ $t("basic.back") }}
                            </button>
                        </div>
                    </AsyncForm>
                </div>
            </div>
            <div v-else-if="step === 'twoFactor'">
                <AsyncForm v-slot="{ action, loading }" :action="twoFactorLogin">
                    <div>
                        <label
                            for="number"
                            class="block text-sm font-satoshiMedium leading-6 text-gray-900"
                            >{{ t("labels.otp") }}</label
                        >
                        <div class="mt-2">
                            <input
                                id="number"
                                v-model="loginData.otp"
                                name="number"
                                type="tel"
                                maxlength="6"
                                required
                                placeholder="* * * * * *"
                                style="letter-spacing: 16px"
                                class="text-center text-gray-800 text-2xl px-4 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus-visible:outline-none focus:ring-primary"
                                @keydown.enter="action" />
                        </div>
                    </div>
                    <div>
                        <AsyncButton
                            :loading="loading"
                            class="duration-300 hover:scale-105 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-satoshiSemibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                            @click="action">
                            {{ $t("auth.login.title") }}
                        </AsyncButton>
                    </div>
                </AsyncForm>
            </div>
            <div v-else-if="step === 'forgot'">
                {{ t("messages.emailSent") }}
            </div>
        </transition>
        <div
            class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm animate__animated animate__fadeIn animate__delay-1s">
            <p class="mt-10 text-center text-sm text-gray-500">
                {{ t("messages.noAccount") }}
                <router-link
                    :to="{ name: 'register' }"
                    class="font-satoshiSemibold leading-6 text-primary hover:text-primary">
                    {{ $t("auth.register") }}
                </router-link>
            </p>
        </div>
    </div>
</template>

<i18n lang="json">
{
    "de": {
        "labels": {
            "email": "E-Mail-Adresse",
            "password": "Passwort",
            "stayLoggedIn": "Eingeloggt bleiben?",
            "forgotPassword": "Passwort vergessen",
            "otp": "Bestätigungscode"
        },
        "messages": {
            "resendEmailPrompt": "Du musst dich noch freischalten. Email neu senden?",
            "waitingForAuth": "Warte auf Auth",
            "emailSent": "Email gesendet falls vorhanden",
            "noAccount": "Noch kein Account?"
        }
    }
}
</i18n>

<style scoped></style>
