import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client/core"
import { onError } from "@apollo/client/link/error"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import { createClient } from "graphql-ws"

import { i18n } from "@/modules/i18n.module"
import { pinia } from "@/modules/pinia.module"

import { useAuthStore } from "@/stores/authStore"
import { useConfigStore } from "@/stores/configStore"
import { useInfoStore } from "@/stores/infoStore"

const authStore = useAuthStore(pinia)
const configStore = useConfigStore(pinia)

window.addEventListener("offline", () => {
    configStore.online = false
    console.log("Browser: offline")
})
window.addEventListener("online", () => {
    configStore.online = true
    console.log("Browser: online")
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
    const infoStore = useInfoStore(pinia)
    const { t } = i18n.global

    if (networkError) {
        configStore.serverOnline = false
        console.error("Network error:", networkError)
    }
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.error(
                `[GraphQL error]: Message: ${message}, Locations: ${locations}, Path: ${path}`
            )
            infoStore.addMessage({
                message: t("errorCodes." + (message ?? "UnknownError")),
                title: t("basic.error"),
                type: "error"
            })
            // Bei einem speziellen Fehler (z.B. abgelaufenem Token) ausloggen
            if (extensions && extensions.logout) {
                authStore.logout()
            }
        })
    }
})

const onlineLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        configStore.serverOnline = true
        return response
    })
})

const httpLink = new HttpLink({ uri: configStore.projectURL + "graphql" })

const wsLink = new GraphQLWsLink(
    createClient({
        connectionParams: () => {
            if (!authStore.authToken) {
                throw new Error("Bitte einloggen")
            }
            return {
                headers: {
                    Authorization: `Bearer ${authStore.authToken}`
                }
            }
        },
        lazy: true,
        on: {
            closed: () => {
                console.log("closed", configStore.serverOnline)
                configStore.serverOnline = false
                console.log("closed", configStore.serverOnline)
            },
            connected: () => {
                configStore.online = true
                configStore.serverOnline = true
            }
        },
        url: "ws://localhost:2011/graphql"
    })
)

const terminatingLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === "OperationDefinition" && definition.operation === "subscription"
    },
    wsLink,
    httpLink
)

// Kette die Links: Zuerst onlineLink und errorLink, dann der terminatingLink
const link = ApolloLink.from([onlineLink, errorLink, terminatingLink])

export const apolloClientModule = new ApolloClient({
    cache: new InMemoryCache(),
    link
})
