import { DefaultApolloClient } from "@vue/apollo-composable"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { createApp } from "vue"
//@ts-ignore
import VueMatomo from "vue-matomo"

import { apolloClientModule } from "@/modules/apolloClient.module"
import { i18n } from "@/modules/i18n.module"
import { pinia } from "@/modules/pinia.module"

import App from "./App.vue"
import router from "./router"

import "./assets/css/index.scss"

const app = createApp(App)

app.use(pinia)
    .use(i18n)
    .use(router)
    .use(VueMatomo, {
        disableCookies: true,
        host: "https://matomo.reavo.dev",
        router,
        siteId: parseInt(import.meta.env.VITE_MATOMO_SITE_ID ?? 1)
    })

app.config.warnHandler = () => {}
app.provide(DefaultApolloClient, apolloClientModule)

app.mount("#app")

//@ts-ignore
window._paq.push(["trackPageView"])
