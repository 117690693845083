import axios, { type AxiosResponse } from "axios"

import type { AxiosResponseReturn } from "@/interfaces/basic.interface"

import { i18n } from "@/modules/i18n.module"

import { useAuthStore } from "@/stores/authStore"
import { useInfoStore } from "@/stores/infoStore"
import { useUserStore } from "@/stores/userStore"

export default abstract class AbstractHttpService {
    protected get = async <T>(
        url: string,
        params: object = {},
        headers: object = {},
        skipNotification: boolean = false
    ): Promise<AxiosResponseReturn<T>> => {
        return new Promise((resolve, reject) => {
            axios
                .get(url, { headers, params })
                .then((response) => {
                    resolve(this.returnResult(response))
                })
                .catch((e) => {
                    this.errorNotification(e.response, skipNotification)
                    reject(e)
                })
        })
    }

    protected post = async <T>(
        url: string,
        data: object = {},
        headers: object = {},
        skipNotification: boolean = false
    ): Promise<AxiosResponseReturn<T>> => {
        return new Promise((resolve, reject) => {
            axios
                .post(url, data, { headers })
                .then((response) => {
                    resolve(this.returnResult(response))
                })
                .catch((e) => {
                    this.errorNotification(e.response, skipNotification)
                    reject(e)
                })
        })
    }

    protected put = async <T>(
        url: string,
        data: object = {},
        headers: object = {},
        skipNotification: boolean = false
    ): Promise<AxiosResponseReturn<T>> => {
        return new Promise((resolve, reject) => {
            axios
                .put(url, data, { headers })
                .then((response) => {
                    resolve(this.returnResult(response))
                })
                .catch((e) => {
                    this.errorNotification(e.response, skipNotification)
                    reject(e)
                })
        })
    }

    protected patch = async <T>(
        url: string,
        data: object = {},
        headers: object = {},
        skipNotification: boolean = false
    ): Promise<AxiosResponseReturn<T>> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(url, data, { headers })
                .then((response) => {
                    resolve(this.returnResult(response))
                })
                .catch((e) => {
                    this.errorNotification(e.response, skipNotification)
                    reject(e)
                })
        })
    }

    protected remove = async <T>(
        url: string,
        params: object = {},
        headers: object = {},
        skipNotification: boolean = false
    ): Promise<AxiosResponseReturn<T>> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(url, { headers, params })
                .then((response) => {
                    resolve(this.returnResult(response))
                })
                .catch(async (e) => {
                    await this.errorNotification(e.response, skipNotification)
                    reject(e)
                })
        })
    }

    private errorNotification = async (
        response: AxiosResponse<any, any>,
        skipNotification: boolean
    ) => {
        const info = useInfoStore()
        const auth = useAuthStore()
        const { t } = i18n.global

        if (response && "data" in response) {
            if (!skipNotification) {
                info.addMessage({
                    message: t(
                        "errorCodes." + (response.data.status?.message ?? response.data.statusCode)
                    ),
                    title: t("basic.error"),
                    type: "error"
                })
            }
            if (response.data.statusCode === 498) {
                console.log("logout")
                await auth.logout()
            }
        } else {
            info.addMessage({
                message: t("errorCodes.NoConnection"),
                title: t("basic.error"),
                type: "error"
            })
        }
    }

    private returnResult = <T>(response: AxiosResponse<any, any>): AxiosResponseReturn<T> => {
        const authStore = useAuthStore()
        const userStore = useUserStore()
        try {
            if (response.data?.user) {
                userStore.user = response.data.user
                userStore.role = response.data.role
                authStore.authAreaArray = response.data.rightSection
            }
        } catch (e) {
            console.log(e, "responseUser")
        }
        return {
            data: response.data.data,
            httpCode: response.status,
            status: response.data.status,
            user: response.data.user
        }
    }
}
