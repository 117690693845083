<script setup lang="ts">
import { watch } from "vue"
import { useI18n } from "vue-i18n"

import { useConfigStore } from "@/stores/configStore"

const configStore = useConfigStore()
const { t } = useI18n()

watch(
    () => configStore.serverOnline,
    () => {
        console.log(configStore.serverOnline)
    }
)
</script>

<template>
    <div
        class="w-full h-12 flex-none z-100 transition-all duration-500 flex justify-center items-center bg-gray-500 text-white text-xl"
        :class="configStore.serverOnline && configStore.online ? 'max-h-0' : 'max-h-96'">
        <transition name="fade" mode="out-in">
            <span v-if="!configStore.online">{{ t("networkError") }}</span>
            <span v-else-if="!configStore.serverOnline">{{ t("serverOffline") }}</span>
        </transition>
    </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<i18n>
{
  "de": {
    "serverOffline": "Leider sind wir momentan offline.",
    "networkError": "Du bist offline."
  }
}
</i18n>
